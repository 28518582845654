import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { DamageFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { ConfigFacade } from "@cg/config";
import { Icon, List } from "@cg/content-api/typescript-interfaces";
import { OpportunityConfig } from "@cg/core/interfaces";
import { AddFormControls } from "@cg/core/types";
import { calendarIcon, phoneIcon } from "@cg/icon";
import {
  ChannelSwitchReason,
  OLB_PROCESS_FLOW_MODEL,
  OlbHeadlineComponent,
  OpportunityFunnelCostsExitIds,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  BaseButtonComponent,
  DamageWindow,
  ListComponent,
  OverlayService,
  SplitViewComponent,
  Tab,
  TabsComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../../services/exit-node-resolver.service";
import { BaseDirective } from "../../core/directives/base/base.directive";
import { damageWindowToTrackingCase } from "../utils/tracking-case-to-damage-window.util";

@Component({
  selector: "cg-opportunity-funnel-costs",
  templateUrl: "./opportunity-funnel-costs.component.html",
  styleUrls: ["./opportunity-funnel-costs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    AsyncPipe,
    TranslocoPipe,
    SplitViewComponent,
    OlbHeadlineComponent,
    TabsComponent,
    ListComponent,
    BaseButtonComponent
  ]
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class OpportunityFunnelCostsComponent extends BaseDirective<AddFormControls<any>> implements OnInit {
  private selectedIndex = 0;
  private listContentKasko: List = {
    type: "check",
    items: ["opportunityFunnelCosts.list.kasko.item1", "opportunityFunnelCosts.list.kasko.item2"],
    ngTemplate: "cgList"
  };
  private listContentNoKasko: List = {
    type: "check",
    items: ["opportunityFunnelCosts.list.noKasko.item1", "opportunityFunnelCosts.list.noKasko.item2"],
    ngTemplate: "cgList"
  };

  public get isKaskoSelected(): boolean {
    return this.selectedIndex === 0;
  }

  public get listContent(): List {
    return this.isKaskoSelected ? this.listContentKasko : this.listContentNoKasko;
  }

  public get isMobile$(): Observable<boolean> {
    return this.overlayService.isMobile$;
  }

  public isSideWindowDamage = false;

  public readonly calendarIcon: Icon = calendarIcon;
  public readonly phoneIcon: Icon = phoneIcon;
  public tabs$!: Observable<Tab[]>;

  @ViewChild("kaskoTab")
  public kaskoTab: TemplateRef<unknown>;
  @ViewChild("nonKaskoTab")
  public nonKaskoTab: TemplateRef<unknown>;
  public priceNoKasko: string | null = null;

  private selectedDamage: DamageWindow;

  // eslint-disable-next-line max-params
  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    trackingService: TrackingService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private readonly overlayService: OverlayService,
    private readonly damageFacade: DamageFacade,
    private readonly translocoService: TranslocoService,
    private readonly configFacade: ConfigFacade
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);
  }

  public override async ngOnInit() {
    await super.ngOnInit();

    this.damageFacade.selectedDamage$.subscribe((damage: DamageWindow) => {
      this.selectedDamage = damage;
      this.isSideWindowDamage = damage === DamageWindow.LEFT_SIDE;
      this.cdr.markForCheck();
    });

    this.tabs$ = this.isMobile$.pipe(
      map((isMobile: boolean): [string, string] => [
        this.translocoService.translate(
          isMobile ? "opportunityFunnelCosts.tab.title1Mobile" : "opportunityFunnelCosts.tab.title1"
        ),
        this.translocoService.translate(
          isMobile ? "opportunityFunnelCosts.tab.title2Mobile" : "opportunityFunnelCosts.tab.title2"
        )
      ]),
      map(([kaskoTitle, nonKaskoTitle]: [string, string]): Tab[] => [
        { header: kaskoTitle, template: this.kaskoTab },
        { header: nonKaskoTitle, template: this.nonKaskoTab }
      ])
    );

    this.configFacade.opportunityConfig$.subscribe((config: OpportunityConfig) => {
      this.priceNoKasko = this.isSideWindowDamage ? config.price_side : config.price_rear;
      this.cdr.markForCheck();
    });
  }

  public initFormGroup(): void {}

  public setFormValues(): void {}

  public getExitIdForSavedForm(): Observable<OpportunityFunnelCostsExitIds> {
    this.trackCosts();
    return of("default");
  }

  public trackCosts(): void {
    this.trackingService.trackEvent({
      eventAction: "opportunity-funnel-costs",
      eventLabel: this.isKaskoSelected ? "comprehensive-insurance" : "no-insurance",
      "opportunity-funnel": {
        case: damageWindowToTrackingCase(this.selectedDamage)
      }
    } as Partial<TrackingEvent>);
  }

  public saveForm(): void {}

  public setSelectedIndex(index: number): void {
    this.selectedIndex = index;
  }

  public goToChannelSwitch(): void {
    let reason: ChannelSwitchReason;

    switch (this.selectedDamage) {
      case DamageWindow.LEFT_SIDE:
        reason = "SIDE_WINDOW";
        break;
      case DamageWindow.REAR:
        reason = "REAR_WINDOW";
        break;
    }

    this.processFacade.setChannelSwitchReason(reason);
    this.skipFormWithExitId("channelSwitch");
  }
}
