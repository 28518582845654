@if (offerWiper()) {
  @if (bookedWiper()) {
    <cg-wiper-booked
      [wiperPriceIsReduced]="wiperPriceIsReduced()"
      [wipersPicture]="wipersPicture"
      [price]="wiperPrice()"
    ></cg-wiper-booked>
  } @else {
    <cg-wiper-offer
      [wiperPriceIsReduced]="wiperPriceIsReduced()"
      [wipersPicture]="wipersPicture"
      [bookedProtect]="bookedProtect()"
      [bookedWiper]="bookedWiper()"
      [price]="wiperPrice()"
    ></cg-wiper-offer>
  }
}
