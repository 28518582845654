/* eslint-disable sonarjs/no-duplicate-string */

import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { getISOWeek, getYear } from "date-fns";
import { HeadlineType } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { Appointment, AvailableServiceCenters, DrawerComponent } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { AppointmentsPerWeek } from "../../interfaces/appointments-per-week.interface";
import { NewAppointmentTileService } from "../../services/new-appointment-tile.service";
import { NewAppointmentAllScAppointmentsWeekComponent } from "../new-appointment-all-sc-appointments-week/new-appointment-all-sc-appointments-week.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-all-sc-appointments-dialog",
  templateUrl: "./new-appointment-all-sc-appointments-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, DrawerComponent, OlbHeadlineComponent, NewAppointmentAllScAppointmentsWeekComponent]
})
export class NewAppointmentAllScAppointmentsDialogComponent implements OnInit {
  public readonly headlineType = HeadlineType;
  public appointmentsPerWeek: AppointmentsPerWeek[] = [];

  @Input()
  public selectedSc: AvailableServiceCenters;
  @Input()
  public availableAppointments: Appointment[] = [];

  public constructor(private readonly newAppointmentTitleService: NewAppointmentTileService) {}

  public get location(): string {
    if (!this.selectedSc.sublocality) {
      return this.selectedSc.city;
    }

    return `${this.selectedSc.city} - ${this.selectedSc.sublocality}`;
  }

  public ngOnInit(): void {
    this.appointmentsPerWeek = Object.values(
      this.availableAppointments
        .sort(
          (a: Appointment, b: Appointment) =>
            new Date(a.customerAppointmentStart).getTime() - new Date(b.customerAppointmentStart).getTime()
        )
        .reduce((acc: unknown, appointment: Appointment) => {
          const week = getISOWeek(new Date(appointment.customerAppointmentStart));
          const year = getYear(new Date(appointment.customerAppointmentStart));

          const weekKey = `${year}-${week}`;

          if (!acc[weekKey]) {
            acc[weekKey] = { title: this.newAppointmentTitleService.getWeekTitle(appointment), appointments: [] };
          }

          // TODO: Refactor this, deduplication should be handled in the backend when we do the refactoring
          const duplicateEntry = acc[weekKey].appointments.find(
            (item: Appointment) =>
              item.customerAppointmentStart === appointment.customerAppointmentStart &&
              item.customerAppointmentEnd === appointment.customerAppointmentEnd
          );

          if (!duplicateEntry) {
            acc[weekKey].appointments = [...acc[weekKey].appointments, appointment];
          }

          return acc;
        }, {})
    );
  }
}
