import { CommonModule } from "@angular/common";
import { Component, computed, effect, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { Feature, FeatureToggleFacade } from "@cg/feature-toggle";
import { CustomerCaseFacade } from "@cg/olb/state";
import { ConfigFacade } from "@cg/config";
import { Picture } from "@cg/content-api/typescript-interfaces";
import { WiperConfig } from "@cg/core/interfaces";
import { environment } from "@cg/environments";
import { VAPsEventFlag } from "@cg/olb/shared";
import { AdditionalProduct } from "@cg/shared";
import { WiperPrice } from "../../interfaces/wiper-price.interface";
import { WiperBookedComponent } from "../wiper-booked/wiper-booked.component";
import { WiperOfferComponent } from "../wiper-offer/wiper-offer.component";

@Component({
  selector: "cg-wiper-booking",
  standalone: true,
  imports: [CommonModule, WiperBookedComponent, WiperOfferComponent],
  templateUrl: "./wiper-booking.component.html"
})
export class WiperBookingComponent {
  public readonly configFacade = inject(ConfigFacade);
  public readonly customerCaseFacade = inject(CustomerCaseFacade);
  public readonly featureToggleFacade = inject(FeatureToggleFacade);

  public readonly ERROR_WIPER_PRICE_INVALID =
    "Wiper: Regular wiper price was not available, so wiper can't be offered (this does not mean it would be offered in all cased, even if price is available)!";
  private readonly ERROR_WIPER_REDUCTION_PRICE_INVALID =
    "Wiper: Reduction price was not available or valid, so a reduced price can't be offered (this does not mean it would be offered in all cased, even if reduced price is available).";

  public readonly wipersPicture: Picture = {
    sizes: [],
    source: `${environment.assetPath}/olb/wipers-offer/bosch-wiper.webp`,
    alt: "wiperOffer.imageAlt.wipersPicture",
    ngTemplate: "cgPicture"
  };

  public bookedProtect = toSignal(this.customerCaseFacade.protectProduct$);
  public bookedWiper = toSignal(this.customerCaseFacade.wiperProduct$);
  public wiperPriceIsReduced = computed(() => this.bookedProtect() && this.wiperPrice().reduction_valid);
  public wiperPrice = computed(() => this.mapWiperPrice(this.wiperConfig()));
  public offerWiper = computed(() => this.featureFlagWiperActive() && this.wiperPrice().price_wiper_regular !== null);

  private featureFlagWiperActive = toSignal(
    this.featureToggleFacade.isFeatureActive$(Feature.WIPER_OFFER_ON_CONFIRMATION)
  );
  private wiperConfig = toSignal(this.configFacade.wiperConfig$);

  public constructor() {
    effect(
      () => {
        if (this.featureFlagWiperActive() && this.offerWiper() && !this.bookedWiper()) {
          this.customerCaseFacade.offerVAP(AdditionalProduct.WIPER, [VAPsEventFlag.AFTER_CONFIRMATION]);
        }
      },
      { allowSignalWrites: true }
    );
  }

  private mapWiperPrice(config: WiperConfig): WiperPrice {
    if (!config || !config.price_wiper_regular || Number.isNaN(Number(config.price_wiper_regular))) {
      console.error(this.ERROR_WIPER_PRICE_INVALID);
      return {
        price_wiper_regular: null,
        price_wiper_reduced: null,
        price_wiper_reduction_amount: null,
        reduction_valid: false
      };
    }

    const reductionAmountNumber: number = Number(config.price_wiper_regular) - Number(config.price_wiper_reduced);
    const reductionValid = !(
      config.price_wiper_reduced === null ||
      Number.isNaN(reductionAmountNumber) ||
      reductionAmountNumber < 0.1
    );

    if (config.price_wiper_reduced === null || Number.isNaN(reductionAmountNumber)) {
      console.error(this.ERROR_WIPER_REDUCTION_PRICE_INVALID);
    }

    return {
      price_wiper_regular: config.price_wiper_regular?.replace(".", ","),
      price_wiper_reduced: config.price_wiper_reduced?.replace(".", ","),
      price_wiper_reduction_amount: reductionAmountNumber?.toFixed(0).replace(".", ","),
      reduction_valid: reductionValid
    };
  }
}
